<template>
    <div class="module">
        <van-nav-bar title="照明控制" left-arrow  @click-left="$router.go(-1)" />
        <van-steps direction="vertical" :active="15" active-color="#1677ff" class="setps-content">
            <van-step v-for="(item,index) in devList" :key="index">
                <div class="title">
                    {{item.name}}
                </div>
                <div v-if="item.type === 1">
                    <div class="marbtm5">亮度值：{{ item.lum }} lux</div>
                    <div class="marbtm5">故障报警：
                        <van-tag size="medium" type="primary" v-if="item.status">正常</van-tag>
                        <van-tag size="medium" type="warning" v-else>故障</van-tag>
                    </div>
                </div>
                <div v-else>
                    <div class="marbtm5">调光值：{{item.lum}} lux</div>
                    <div class="marbtm5">设备温度：{{item.temp}} ℃</div>
                    <div class="marbtm5">控制状态：
                        <van-tag size="medium" type="primary" v-if="item.status">自动</van-tag>
                        <van-tag size="medium" type="warning" v-else>手动</van-tag>
                    </div>
                </div>
                <div>更新时间：{{item.time}}</div>
            </van-step>
        </van-steps>
    </div>
</template>

<script>
export default {
    data(){
        return{
            devList:[
                {name:'洞外亮度检测-进京1',type:1,status:1,lum:'4585.0',time:'2021-11-01 12:30'},
                {name:'洞内照度检测-进京1',type:1,status:1,lum:'0.0',time:'2021-11-01 08:30'},
                {name:'进京基本照明-1',type:2,status:1,temp:'32.0',lum:'100',time:'2021-11-01 11:30'},
                {name:'进京基本照明-2',type:2,status:1,temp:'30.2',lum:'80',time:'2021-11-01 09:30'},
                {name:'进京加强照明-1',type:2,status:0,temp:'32.9',lum:'100',time:'2021-11-01 12:30'},
                {name:'进京加强照明-2',type:2,status:1,temp:'32.3',lum:'100',time:'2021-11-01 02:20'},
                {name:'进京应急照明-1',type:2,status:0,temp:'32.2',lum:'100',time:'2021-11-01 12:30'},
                {name:'进京应急照明-2',type:2,status:1,temp:'30.4',lum:'100',time:'2021-11-02 12:00'},
                {name:'洞外亮度检测-出京1',type:1,status:1,lum:'1667.5',time:'2021-11-02 12:10'},
                {name:'洞内照度检测-出京1',type:1,status:1,lum:'0.0',time:'2021-11-02 14:30'},
                {name:'出京基本照明-1',type:2,status:1,temp:'28.9',lum:'100',time:'2021-11-02 14:50'},
                {name:'出京基本照明-2',type:2,status:0,temp:'32.2',lum:'100',time:'2021-11-02 15:30'},
                {name:'出京加强照明-1',type:2,status:1,temp:'32.5',lum:'80',time:'2021-11-02 16:00'},
                {name:'出京加强照明-2',type:2,status:0,temp:'35.0',lum:'100',time:'2021-11-02 18:10'},
                {name:'出京应急照明-1',type:2,status:0,temp:'33.0',lum:'100',time:'2021-11-02 19:00'},
                {name:'出京应急照明-2',type:2,status:1,temp:'30.2',lum:'100',time:'2021-11-02 19:10'},
            ]
        }
    },
    methods:{
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/tunnel/module.scss';
</style>